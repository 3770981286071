import consumer from "./consumer"

consumer.subscriptions.create("FinishedArticleRegenerateImageChannel", {
  connected() {
    console.log("Connected to FinishedArticleRegenerateImageChannel");
  },

  disconnected() {
    console.log("Disconnected from FinishedArticleRegenerateImageChannel");
  },

  received(data) {
    console.log(data.content.url)
    console.log(data.content.image_index)
    let article_data = {
      id: data.content.article_id,
      image_index: data.content.image_index
    }
    $.ajax({
      method: "get",
      url: '/api/v1/articles/retrieve_article_image',
      data: article_data
    }).done(function(response) {
      console.log(response);
      let article_image_url = response.article_image_url;
      let article_image_link = $('.article_image_link_' + data.content.image_index);
      article_image_link.attr("href", article_image_url);
      article_image_link.find('img').attr("src", article_image_url);

      if (response.is_article_owner) {
        $('.navbar_total_image_count_container').html(response.image_count);
      }

      let article_image_btn_group = $('.article_image_btn_group_' + data.content.image_index);
      article_image_btn_group.find('.article_image_download_btn').data("image", article_image_url);
      article_image_btn_group.find('.article_image_rewrite_btn').data("image", article_image_url);
      article_image_btn_group.find('.article_image_zoom_btn').data("image", article_image_url);

      refreshFsLightbox();

      article_image_link.parent().unblock();
    });

  }
});